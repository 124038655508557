<template>
  <div>
    <div v-if="isSubmitted">
      <order-confirmation
        :scope-id="scopeId"
        :configuration="configuration"
        :product-name="selectedProduct.name"
      />
      <div class="product-configuration__close">
        <telia-button variant="primary" @click="closeModal()">
          {{ t("CLOSE") }}
        </telia-button>
      </div>
    </div>
    <div v-else t-id="product-configuration" class="product-configuration">
      <div>
        <accordion-item
          id="user"
          :title="t('USER_TITLE')"
          :open="openAccordion.user"
          :disabled="isSubmitting"
          :aria-expanded="openAccordion.user"
          :aria-label="t('ALLY.LABEL.USER_INFO')"
          @expand="toggleAccordion('user')"
        >
          <template v-slot:subtitle>
            <telia-p :aria-hidden="openAccordion.user">
              <span v-if="configuration.user && configuration.user.reference">
                {{ configuration.user.reference }}
              </span>
              <span v-else class="sub-accordion-label">
                {{ t("NONE_SPECIFIED") }}
              </span>
            </telia-p>
          </template>
          <template v-slot:content>
            <user-info
              :selected-customer="selectedCustomer"
              @setUser="configuration.user = $event"
              @valid="validState.user = $event"
            />
          </template>
        </accordion-item>
        <accordion-item
          id="msisdn"
          :title="t('PHONE_NUMBER')"
          :open="openAccordion.phoneNumber"
          :show-caret="isVoiceSubscription"
          :disabled="isSubmitting || !isVoiceSubscription"
          :aria-expanded="openAccordion.phoneNumber"
          :aria-label="t('ALLY.LABEL.PHONE_NUMBER')"
          @expand="toggleAccordion('phoneNumber')"
        >
          <template v-slot:subtitle>
            <telia-p :aria-hidden="openAccordion.phoneNumber">
              <span v-if="configuration.phoneNumber && configuration.phoneNumber.formattedMsisdn">
                {{ configuration.phoneNumber.formattedMsisdn }}
              </span>
              <span v-else-if="isVoiceSubscription" class="sub-accordion-label">
                {{ t("NONE_SELECTED") }}
              </span>
              <span v-else class="sub-accordion-label">
                {{ t("NUMBER_ASSIGNED_IN_ORDER_CONFIRMATION") }}
              </span>
            </telia-p>
          </template>
          <template v-slot:content>
            <phone-number
              v-if="isVoiceSubscription"
              :scope-id="scopeId"
              :tscid="selectedOrganisation.tscid"
              :selected-customer="selectedCustomer"
              :type="selectedProduct.category === 'DATA' ? 'MBB' : selectedProduct.category"
              @setPhoneNumber="handlePhoneNumberChange($event)"
              @valid="validState.phoneNumber = $event"
            />
          </template>
        </accordion-item>

        <accordion-item
          id="simcard"
          :title="hideEsim ? t('SIMCARD_TITLE') : t('SIMCARD_AND_ESIM_TITLE')"
          :open="openAccordion.simcard"
          :disabled="isSubmitting"
          :aria-expanded="openAccordion.simcard"
          :aria-label="t('ALLY.LABEL.SIMCARD')"
          @expand="toggleAccordion('simcard')"
        >
          <template v-slot:subtitle>
            <telia-p :aria-hidden="openAccordion.simcard">
              <span v-if="configuration.simcard">
                <span v-if="configuration.simcard.component === 'new-esim'">
                  {{ t("SELECTED_NEW_ESIM") }}
                </span>
                <span v-else-if="configuration.simcard.component === 'new-sim'">
                  {{ t("SELECTED_NEW_SIMCARD") }}
                </span>
                <span v-else-if="configuration.simcard.component === 'existing-sim'">
                  {{
                    t("SELECTED_EXISTING_SIMCARD", {
                      iccNumber: configuration.simcard.iccNumber || t("ICC_NUMBER_MISSING"),
                    })
                  }}
                </span>
              </span>
              <span v-else class="sub-accordion-label">
                {{ t("NONE_SPECIFIED") }}
              </span>
            </telia-p>
          </template>
          <template v-slot:content>
            <simcard-selector
              @setSimcard="configuration.simcard = $event"
              @valid="validState.simcard = $event"
              :hideEsim="hideEsim"
            />
          </template>
        </accordion-item>

        <accordion-item
          id="account"
          :title="t('INVOICE_TITLE')"
          :open="openAccordion.account"
          :disabled="isSubmitting"
          show-overflow
          :aria-expanded="openAccordion.account"
          :aria-label="t('ALLY.LABEL.ACCOUNT')"
          @expand="toggleAccordion('account')"
        >
          <template v-slot:subtitle>
            <telia-p :aria-hidden="openAccordion.account">
              <span
                v-if="
                  configuration.account &&
                  configuration.account.number &&
                  selectedInvoiceChoice === 'existing-invoice-group'
                "
              >
                {{ configuration.account.billingGroup + " " + configuration.account.reference }}
              </span>
              <div
                v-else-if="
                  configuration.newAccount && selectedInvoiceChoice === 'new-invoice-group'
                "
              >
                <span> {{ t("NEW_INVOICE_GROUP") }} </span><br />
                <span>
                  {{ configuration.newAccount.address || "" }}
                  {{ configuration.newAccount.postalCode || "" }}
                  {{ configuration.newAccount.city || "" }}
                </span>
              </div>
              <span v-else class="sub-accordion-label">
                {{ t("NONE_SPECIFIED") }}
              </span>
            </telia-p>
          </template>
          <template v-slot:content>
            <invoice
              :scope-id="scopeId"
              :tscid="selectedOrganisation.tscid"
              :selected-customer="selectedCustomer"
              :invoice-has-prepaid-subscription="invoiceHasPrepaidSubscription"
              @select-account="configuration.account = $event"
              @new-account="configuration.newAccount = $event"
              @selected-choice="selectedInvoiceChoice = $event"
              @valid="validState.account = $event"
            />
          </template>
        </accordion-item>

        <accordion-item
          id="services"
          :title="t('SERVICES_TITLE')"
          :open="openAccordion.services"
          :disabled="isSubmitting"
          :aria-expanded="openAccordion.services"
          :aria-label="t('ALLY.LABEL.SERVICES')"
          @expand="toggleAccordion('services')"
        >
          <template v-slot:subtitle>
            <telia-p :aria-hidden="openAccordion.services">
              <span :class="{ 'sub-accordion-label': configuration.services.length === 0 }">
                {{ !!configuration.services.length ? t("SECRET_NUMBER") : t("NONE_SPECIFIED") }}
              </span>
            </telia-p>
          </template>
          <template v-slot:content>
            <services @setServices="configuration.services = $event" />
          </template>
        </accordion-item>
        <accordion-item
          id="deliveryAddress"
          :title="t('DELIVERY_ADDRESS')"
          :open="openAccordion.deliveryAddress"
          :disabled="isSubmitting"
          :aria-expanded="openAccordion.deliveryAddress"
          :aria-label="t('ALLY.LABEL.DELIVERY_ADDRESS')"
          @expand="toggleAccordion('deliveryAddress')"
        >
          <template v-slot:subtitle>
            <telia-p :aria-hidden="openAccordion.deliveryAddress">
              <span>{{ configuration.deliveryAddress.name }}</span>
              <br />

              <span v-if="configuration.deliveryAddress.address">
                {{ configuration.deliveryAddress.address }}
                <br />
              </span>

              <span>
                {{
                  configuration.deliveryAddress.zipCode
                    ? configuration.deliveryAddress.zipCode + ", "
                    : ""
                }}
                {{ configuration.deliveryAddress.city ? configuration.deliveryAddress.city : "" }}
              </span>
            </telia-p>
          </template>
          <template v-slot:content>
            <DeliveryAddress
              ref="deliveryAddress"
              :selectedCustomer="selectedCustomer"
              @valid="validState.deliveryAddress = $event"
              @setDeliveryAddress="configuration.deliveryAddress = $event"
            />
          </template>
        </accordion-item>
      </div>

      <!-- Hotfix for missing email on user -->
      <div v-if="loggedInUserInfo && !loggedInUserInfo.mail" class="product-configuration__email">
        <telia-text-input
          type="email"
          inputmode="email"
          :label="t('EMAIL_ADDRESS')"
          :additional="t('EMAIL_ADDRESS_MISSING')"
          :required-error-message="t('EMAIL_ADDRESS_REQUIRED')"
          :email-error-message="t('EMAIL_ADDRESS_INVALID_FORMAT')"
          :value="backupEmail"
          @input="backupEmail = $event.target.value"
          required
        />
      </div>

      <div class="product-configuration__terms">
        <telia-checkbox
          :value="!validState.terms"
          :disabled="isSubmitting"
          :checked="validState.terms"
          :required-error-message="t('MUST_APPROVE_TERMS_AND_CONDITIONS')"
          @change="toggleTerms"
          required
        >
          {{ t("APPROVE_TERMS_AND_CONDITIONS") }}
          <telia-link
            class="product-configuration__terms__link"
            href="/foretag/mybusiness/om/villkor-mybusiness"
            target="_blank"
          >
            <telia-p>{{ t("TERMS") }}</telia-p>
          </telia-link>
        </telia-checkbox>
      </div>
      <div class="product-configuration__submit">
        <telia-button variant="expressive" @click="submitOrder" :disabled="disableSubmit">
          <span>
            {{ isSubmitting ? t("OPENING_SUBSCRIPTION") : t("CONFIRM_OPEN_SUBSCRIPTION") }}
          </span>
        </telia-button>
      </div>
      <page-alert
        v-if="isRejected && newInvoiceAccountCreated"
        class="product-configuration__submit"
        variant="info"
      >
        {{ t("NEW_INVOICE_ACCOUNT_CREATED") }}:
        <strong>{{ configuration.newAccount.number }}</strong>
      </page-alert>
      <page-alert v-if="isRejected" class="product-configuration__submit">
        {{ t(orderErrorMessage) }}
      </page-alert>
    </div>
  </div>
</template>

<script>
import { getLoggedInUserInfo } from "@telia/b2b-logged-in-service";
import {
  corpBillingAccounts,
  corpMobileSubscriptionService,
  corpNumberBooking,
} from "@telia/b2b-rest-client";
import { msisdnMixin } from "./mixins";
import { translateMixin } from "../locale";
import { trackConfirmOrder, trackPurchaseGa4, trackFunnelFailGa4 } from "../helpers/ga";
import { logInfo, logError } from "../helpers";
import PageAlert from "./ui-components/page-alert.vue";
import UserInfo from "./user-info.vue";
import PhoneNumber from "./phone-number/phone-number.vue";
import SimcardSelector from "./sim-card/simcard-selector.vue";
import Invoice from "./invoice/invoice.vue";
import Services from "./services.vue";
import OrderConfirmation from "./order-confirmation";
import AccordionItem from "./ui-components/accordion-item.vue";
import DeliveryAddress from "./deliveryAddress";

const status = Object.freeze({
  IDLE: 0,
  PENDING: 1,
  RESOLVED: 2,
  REJECTED: 3,
});

export default {
  name: "product-configuration",
  mixins: [msisdnMixin, translateMixin],
  components: {
    Invoice,
    OrderConfirmation,
    DeliveryAddress,
    PhoneNumber,
    Services,
    SimcardSelector,
    PageAlert,
    UserInfo,
    AccordionItem,
  },
  props: {
    scopeId: { type: String, required: true },
    selectedOrganisation: { type: Object, required: true },
    selectedCustomer: { type: Object, required: true },
    selectedAgreement: { type: Object, required: true },
    selectedProduct: { type: Object, required: true },
    orgHasNfAgreement: { type: Boolean, default: false },
    isTeliaAdmin: { type: Boolean, default: false },
    agreementGroup: { type: String, required: false },
  },
  data() {
    return {
      submissionState: status.IDLE,
      selectedInvoiceChoice: "new-invoice-group",
      newInvoiceAccountCreated: false,
      extendInactivityTimeoutMinutes: 10,
      openAccordion: {
        phoneNumber: false,
        simcard: false,
        user: true,
        account: false,
        services: false,
        deliveryAddress: false,
      },
      configuration: {
        phoneNumber: {},
        simcard: {},
        user: {},
        account: {},
        newAccount: {},
        services: [],
        deliveryAddress: {},
      },
      validState: {
        phoneNumber: false,
        simcard: false,
        user: false,
        account: false,
        deliveryAddress: false,
        terms: false,
      },
      isNumberSeries: false,
      orderErrorMessage: "",
      invoiceHasPrepaidSubscription: false,
      loggedInUserInfo: null,
      backupEmail: null,
      timeToConfigureOrder: {
        start: null,
        end: null,
        result: null,
      },
    };
  },
  async mounted() {
    this.timeToConfigureOrder.start = new Date();
    this.emitValidity(this.valid);
    this.emitConfigurationChange(this.configuration);
    if (!this.isVoiceSubscription) {
      this.validState.phoneNumber = true;
    }
    this.setInactivityTimer();
    try {
      this.loggedInUserInfo = await getLoggedInUserInfo();
    } catch {
      trackFunnelFailGa4("Kunde inte hämta användarinformation");
      this.orderErrorMessage = "COULD_NOT_FETCH_LOGGED_IN_USER";
      this.submissionState = status.REJECTED;
    }
  },
  computed: {
    isIdle() {
      return this.submissionState === status.IDLE;
    },
    isSubmitting() {
      return this.submissionState === status.PENDING;
    },
    isSubmitted() {
      return this.submissionState === status.RESOLVED;
    },
    isRejected() {
      return this.submissionState === status.REJECTED;
    },
    valid() {
      return !Object.values(this.validState).some((state) => !state);
    },
    hideEsim() {
      return this.selectedProduct.category === "M2M";
    },
    isVoiceSubscription() {
      return this.selectedProduct.category === "VOICE";
    },
    hasNewInvoiceGroup() {
      return this.selectedInvoiceChoice === "new-invoice-group";
    },
    disableSubmit() {
      const userHasEmailAddress = this.loggedInUserInfo?.mail || this.backupEmail;
      return (
        !this.validState.terms || this.isSubmitting || this.isTeliaAdmin || !userHasEmailAddress
      );
    },
  },
  methods: {
    setInactivityTimer() {
      clearTimeout(this.timer);
      const timeout = this.extendInactivityTimeoutMinutes * 60 * 1000;
      this.timer = setTimeout(this.closeModal, timeout);
    },
    async submitOrder() {
      this.submissionState = status.PENDING;
      this.$emit("isSubmitting", true);
      this.closeAllAccordions();

      if (!this.valid) {
        for (const [key, value] of Object.entries(this.validState)) {
          if (!value) {
            this.setAccordionOpen(key);
            logInfo(`incomplete data while submitting - missing information at:  ${key}`);
          }
        }
        this.submissionState = status.IDLE;
        this.$emit("isSubmitting", false);
        return;
      }

      // Hotfix for missing email on user
      if (this.loggedInUserInfo && !this.loggedInUserInfo.mail && !this.backupEmail) {
        this.submissionState = status.IDLE;
        this.$emit("isSubmitting", false);
        return;
      }

      if (this.selectedInvoiceChoice === "new-invoice-group" && !this.newInvoiceAccountCreated) {
        const newInvoiceAccount = {
          name: this.selectedCustomer.name,
          accountReference: this.configuration.newAccount.accountReference,
          address: this.configuration.newAccount.address,
          postalCode: this.configuration.newAccount.postalCode,
          city: this.configuration.newAccount.city,
          category: "MOBILE",
          customerNumber: this.selectedCustomer.customerNumber,
        };
        try {
          const billingAccountResponse =
            await corpBillingAccounts.BillingAccountsControllerService.addBillingAccount(
              this.scopeId,
              this.selectedOrganisation.tscid,
              newInvoiceAccount
            );
          this.configuration.newAccount.accountNumber = billingAccountResponse.accountNumber;
          this.configuration.newAccount.number = billingAccountResponse.number;
          this.newInvoiceAccountCreated = true;
        } catch {
          trackFunnelFailGa4("Kunde inte skapa fakturakonto", this.agreementGroup);
          this.orderErrorMessage = "COULD_NOT_CREATE_NEW_INVOICE_ACCOUNT";
          this.submissionState = status.REJECTED;
          this.$emit("isSubmitting", false);
          return;
        }
      }

      const requestBody = {
        organisationNumber: this.selectedOrganisation.organizationNumber,
        msisdn: this.isVoiceSubscription ? this.configuration.phoneNumber.msisdn : null,
        isNumberSeries: this.isNumberSeries,
        msisdnType:
          this.selectedProduct.category === "DATA" ? "MBB" : this.selectedProduct.category,
        subscriptionType: this.selectedProduct.type,
        subscriptionTypeName: this.selectedProduct.name,
        agreementNumber: this.selectedAgreement.agreementNumber,
        agreementQueue: this.selectedAgreement.agreementQueue,
        iccNumber: this.configuration.simcard.iccNumber || null,
        services: this.configuration.services,
        orderer: {
          email: this.loggedInUserInfo.mail || this.backupEmail,
          firstName: this.loggedInUserInfo.firstName,
          lastName: this.loggedInUserInfo.lastName,
        },
        customer: {
          address: {
            name: this.configuration.deliveryAddress.name,
            street: this.configuration.deliveryAddress.address || "",
            zipCode: this.configuration.deliveryAddress.zipCode,
            city: this.configuration.deliveryAddress.city,
          },
          number: this.selectedCustomer.customerNumber,
        },
        invoice: {
          address: {
            name: this.selectedCustomer.name,
            street: this.hasNewInvoiceGroup
              ? this.configuration.newAccount.address
              : this.configuration.account.address?.address,
            zipCode: this.hasNewInvoiceGroup
              ? this.configuration.newAccount.postalCode
              : this.configuration.account.address?.zipCode,
            city: this.hasNewInvoiceGroup
              ? this.configuration.newAccount.city
              : this.configuration.account.address?.city,
          },
          billingGroup: this.hasNewInvoiceGroup
            ? this.configuration.newAccount.accountNumber
            : this.configuration.account.billingGroup,
          coName: this.hasNewInvoiceGroup
            ? this.configuration.newAccount.accountReference
            : this.configuration.account.reference,
          isNew: this.hasNewInvoiceGroup,
        },
        user: {
          address: {
            name: this.configuration.deliveryAddress.name,
            street: this.configuration.deliveryAddress.address || "",
            zipCode: this.configuration.deliveryAddress.zipCode,
            city: this.configuration.deliveryAddress.city,
          },
          subscriptionReference: this.configuration.user.reference,
          name: this.configuration.user.name,
        },
        simFormat:
          this.configuration.simcard.type === "ESIM" ? this.configuration.simcard.type : null,
      };

      try {
        const subscriptionResponse =
          await corpMobileSubscriptionService.MobileSubscriptionControllerService.createMobileSubscription(
            this.scopeId,
            requestBody
          );

        if (!this.isVoiceSubscription) {
          this.configuration.phoneNumber.msisdn = subscriptionResponse.msisdn;
          this.configuration.phoneNumber.formattedMsisdn = this.formatMsisdn(
            this.configuration.phoneNumber.msisdn
          );
        }
      } catch (e) {
        trackFunnelFailGa4("Kunde inte skapa abonnemang", this.agreementGroup);
        this.orderErrorMessage = this.handleErrorKeys(e?.body?.errorKey);
        this.submissionState = status.REJECTED;
        this.$emit("isSubmitting", false);
        return;
      }

      if (this.isVoiceSubscription) {
        const bookingId =
          this.configuration?.phoneNumber?.bookingId[this.configuration.phoneNumber.component];

        try {
          await corpNumberBooking.PublicNumberBookingControllerService.finalizeBooking(
            this.scopeId,
            this.selectedOrganisation?.tscid,
            bookingId,
            this.configuration?.phoneNumber?.msisdn
          );
        } catch {
          // Subscription already opened but other booked numbers won't be released until booking-timeout.
        }
      }

      await this.logOrderData();
      this.submissionState = status.RESOLVED;
      this.$emit("isSubmitting", false);
      trackConfirmOrder(this.selectedProduct);
      trackPurchaseGa4(this.selectedProduct, this.agreementGroup);
    },
    handleErrorKeys(errorKey) {
      logError("errorKey: " + errorKey);

      switch (errorKey) {
        case "SPRINGFIELD_CREDIT_CHECK_DENIED":
          return "SPRINGFIELD_CREDIT_CHECK_DENIED";
        case "CREDIT_EVALUATION_ERROR":
          return "CREDIT_EVALUATION_ERROR";
        case "SPRINGFIELD_CREDIT_CONTROL_REQUIRED":
          return "SPRINGFIELD_CREDIT_CONTROL_REQUIRED";
        case "BILLING_ACCOUNT_HAS_PREPAID_SUBSCRIPTION":
          this.handlePrepaidInvoice();
          return "BILLING_ACCOUNT_HAS_PREPAID_SUBSCRIPTION";
        case "SPRINGFIELD_SIMCARD_ALREADY_REGISTERED":
          return "SPRINGFIELD_SIMCARD_ALREADY_REGISTERED";
        default:
          return "COULD_NOT_OPEN_SUBSCRIPTION";
      }
    },
    handlePrepaidInvoice() {
      this.invoiceHasPrepaidSubscription = true;
      this.setAccordionOpen("account");
    },
    handlePhoneNumberChange(event) {
      this.configuration.phoneNumber = event.phoneNumber;
      this.isNumberSeries = event.isNumberSeries;
    },
    toggleTerms() {
      this.validState.terms = !this.validState.terms;
    },
    closeAllAccordions() {
      Object.keys(this.openAccordion).forEach((key) => (this.openAccordion[key] = false));
    },
    setAccordionOpen(accordionKey) {
      this.openAccordion[accordionKey] = true;
    },
    toggleAccordion(accordionKey) {
      this.openAccordion[accordionKey] = !this.openAccordion[accordionKey];
    },
    emitConfigurationChange(configuration) {
      this.$emit("change", configuration);
    },
    emitValidity(valid) {
      this.$emit("valid", valid);
    },
    closeModal() {
      this.$emit("hide-modal");
    },
    logOrderData() {
      this.timeToConfigureOrder.end = new Date();
      this.timeToConfigureOrder.result = Math.round(
        (this.timeToConfigureOrder.end - this.timeToConfigureOrder.start) / 1000
      );
      const orderLog = [
        "Completed order",
        `Selected invoice method: ${this.selectedInvoiceChoice}`,
        `Selected SIM type: ${this.configuration.simcard.type}`,
        `Selected product category: ${
          this.selectedProduct.category === "DATA" ? "MBB" : this.selectedProduct.category
        }`,
        `Selected product name: ${this.selectedProduct.name}`,
        `Sliding modal configuration - time until order in seconds: ${this.timeToConfigureOrder.result}`,
        this.isNumberSeries ? "phone number: number series" : "phone number: new number",
        `ESIM delivery email: ${
          this.loggedInUserInfo.mail === this.configuration.simcard.deliveryEmailAddress
            ? "logged in user email"
            : "another email"
        }`,
        this.selectedProduct.category !== "M2M"
          ? `org has nf agreement: ${this.orgHasNfAgreement}`
          : "",
        this.selectedAgreement.agreementType === "SME" ? "MFA agreement" : "",
      ];
      if (this.configuration.services.length) {
        orderLog.push("order has added service");
      }
      logInfo(orderLog);
    },
  },
  watch: {
    configuration: {
      deep: true,
      handler() {
        this.emitConfigurationChange();
        this.setInactivityTimer();
      },
    },
    "configuration.account": {
      handler(newVal, oldVal) {
        if (newVal?.number !== oldVal?.number && this.invoiceHasPrepaidSubscription) {
          this.invoiceHasPrepaidSubscription = false;
          this.submissionState = status.IDLE;
          this.orderErrorMessage = "";
        }
      },
    },
    valid: "emitValidity",
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/colors/variables";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.sub-accordion-label {
  color: $telia-gray-600;
}

.product-configuration {
  height: 100%;
  margin-top: $telia-spacing-48;

  @media screen and (max-width: 770px) {
    padding: $telia-spacing-12;
  }

  &__email {
    margin-top: $telia-spacing-32;
  }

  &__terms {
    display: flex;
    margin-top: $telia-spacing-32;

    &__link {
      z-index: 9998;
      display: inline-block;
    }
  }

  &__submit {
    margin-top: $telia-spacing-24;
  }

  &__alert {
    margin-top: $telia-spacing-24;
  }

  &__close {
    margin-top: $telia-spacing-48;
  }
}
</style>
