<template>
  <section>
    <section class="order-confirmation__section">
      <telia-heading class="order-confirmation__title" tag="h1" variant="title-200">
        {{ t("THANKS_FOR_YOUR_ORDER") }}
      </telia-heading>
      <telia-p class="order-confirmation__not-searchable" v-html="t('ORDER_IS_NOT_SEARCHABLE')" />
    </section>
    <section class="order-confirmation__section">
      <telia-heading class="order-confirmation__section__title" tag="h2">
        {{ productName }}
      </telia-heading>
      <telia-p>
        {{ configuration.phoneNumber.formattedMsisdn }} / {{ configuration.user.name }}<br />
      </telia-p>
      <telia-p v-html="deliveryMethod" />
    </section>
    <section class="order-confirmation__section">
      <telia-heading class="order-confirmation__section__title" tag="h2">
        {{ t("PRODUCT_DELIVERY_TO") }}
      </telia-heading>
      <telia-p>{{ configuration.deliveryAddress.name }}</telia-p>
      <telia-p v-if="configuration.deliveryAddress.address">
        {{ configuration.deliveryAddress.address }}
      </telia-p>
      <telia-p>
        {{ configuration.deliveryAddress.zipCode }} {{ configuration.deliveryAddress.city }}
      </telia-p>
    </section>
    <section class="order-confirmation__section">
      <telia-heading class="order-confirmation__section__title" tag="h2">
        {{ t("INVOICE_ACCOUNT") }}
      </telia-heading>
      <telia-p v-if="configuration.account && configuration.account.billingGroup">
        {{ configuration.account.billingGroup }}
        {{ configuration.account.reference ? " / " + configuration.account.reference : "" }}
        <br />
        {{ configuration.account.address.name }}<br />
        {{ configuration.account.address.address }}<br />
        {{ configuration.account.address.zipCode }} {{ configuration.account.address.city }}
      </telia-p>
      <telia-p v-else-if="configuration.newAccount && configuration.newAccount.accountNumber">
        {{ t("NEW_INVOICE_GROUP") }}<br />
        {{ configuration.newAccount.accountNumber }}
        {{
          configuration.newAccount.accountReference
            ? " / " + configuration.newAccount.accountReference
            : ""
        }}
        <br />
        {{ configuration.newAccount.address }}<br />
        {{ configuration.newAccount.postalCode }} {{ configuration.newAccount.city }}
      </telia-p>
      <telia-p v-else> </telia-p>
    </section>
    <section v-if="configuration.services.length" class="order-confirmation__section">
      <telia-heading class="order-confirmation__section__title" tag="h2">
        {{ t("SERVICES_TITLE") }}
      </telia-heading>
      <ul v-for="service in configuration.services" :key="service" class="order-confirmation__list">
        <li>
          <telia-p v-html="t(service)" />
        </li>
      </ul>
    </section>
    <section v-if="configuration.simcard.component === 'new-esim'">
      <telia-heading class="order-confirmation__section__title" tag="h2">
        {{ t("SEND_ESIM_QR_CODE") }}
      </telia-heading>
      <telia-p
        v-html="
          t('SEND_ESIM_QR_CODE_DESCRIPTION', { email: configuration.simcard.deliveryEmailAddress })
        "
      />
      <div class="order-confirmation__button">
        <telia-button
          variant="secondary"
          :disabled="sendQrCodeState === 'pending'"
          @click="sendQrCode()"
        >
          {{ t("SEND_QR_CODE") }}
        </telia-button>
      </div>
      <page-alert
        v-if="sendQrCodeState === 'resolved'"
        variant="success"
        class="order-confirmation__alert"
      >
        {{ t("SEND_QR_CODE_RESOLVED", { email: configuration.simcard.deliveryEmailAddress }) }}
      </page-alert>
      <page-alert v-if="sendQrCodeState === 'rejected'" class="order-confirmation__alert">
        {{ t("SEND_QR_CODE_REJECTED") }}
      </page-alert>
    </section>
  </section>
</template>

<script>
import { corpMobileSimcards } from "@telia/b2b-rest-client";
import { trackConfirmOrder } from "../helpers/ga";
import { translateMixin } from "../locale";
import PageAlert from "./ui-components/page-alert.vue";

export default {
  name: "order-confirmation",
  mixins: [translateMixin],
  components: {
    PageAlert,
  },
  props: {
    scopeId: {
      type: String,
      required: true,
    },
    configuration: {
      type: Object,
      required: true,
    },
    productName: {
      type: String,
      required: true,
    },
  },
  computed: {
    deliveryMethod() {
      switch (this.configuration.simcard.component) {
        case "new-sim":
          return this.t("IS_SENT_BY_POST");
        case "new-esim":
          return this.t("IS_SENT_BY_EMAIL_TO", {
            email: this.configuration.simcard.deliveryEmailAddress,
          });
        case "existing-sim":
          return this.t("EXISTING_SIM_CARD_DELIVERY", {
            iccNumber: this.configuration.simcard.iccNumber,
          });
        default:
          return "";
      }
    },
  },
  data() {
    return {
      sendQrCodeState: "idle",
    };
  },
  mounted() {
    trackConfirmOrder(this.productName);
  },
  methods: {
    async sendQrCode() {
      this.sendQrCodeState = "pending";
      try {
        const requestData = {
          msisdn: this.configuration.phoneNumber.msisdn,
          email: this.configuration.simcard.deliveryEmailAddress,
        };

        await corpMobileSimcards.MobileSimcardsControllerService.sendQrCodeEmailForNewSubscription(
          this.scopeId,
          requestData        );
      } catch {
        this.sendQrCodeState = "rejected";
        return;
      }
      this.sendQrCodeState = "resolved";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";

.order-confirmation {
  &__title {
    margin-bottom: $telia-spacing-8;
  }
  &__not-searchable {
    max-width: 48rem;
  }
  &__section {
    &__title {
      margin-bottom: 0;
    }
    margin-bottom: $telia-spacing-32;
  }
  &__button {
    margin-top: $telia-spacing-16;
  }
  &__alert {
    margin-top: $telia-spacing-16;
  }
  &__list {
    list-style: none;
  }
}
</style>
